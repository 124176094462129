import React from 'react';
import CreateUser from '../components/users/create-user/CreateUser';

const CreateNewUser = () => {
  return (
    <div>
      <CreateUser />
    </div>
  );
};

export default CreateNewUser;
