import React from 'react';
import RegisterForm from '../components/auth/RegisterForm';

const RegisterPage = () => {
  return (
    <div>
      {/*   <h1 style={{ textAlign: 'center', padding: '10% 0 0 0' }}>
        Welcome to Fenix Registration
        <br />
        Register System
      </h1> */}
      <RegisterForm />
    </div>
  );
};

export default RegisterPage;
