import Dashboard from '../components/dashboard/Dashboard';

const HomePage = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default HomePage;
